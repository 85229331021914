import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Icon } from '../js/components/Icon';
import { ThemeProvider } from 'styled-components';
import { themeConWeb, themeEnterprise, themeGO } from '../js/themes';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "icon"
    }}>{`Icon`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Icon} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<ThemeProvider theme={themeConWeb}>\n  <Icon variant=\"daily\" />\n  <Icon variant=\"facebook\" />\n  <Icon variant=\"hamburger\" />\n  <Icon variant=\"instagram\" />\n  <Icon variant=\"linkedin\" />\n  <Icon variant=\"meetings\" />\n  <Icon variant=\"twitter\" />\n</ThemeProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Icon,
      ThemeProvider,
      themeConWeb,
      themeEnterprise,
      themeGO
    }} mdxType="Playground">
  <ThemeProvider theme={themeConWeb} mdxType="ThemeProvider">
  <Icon variant='daily' mdxType="Icon" />
  <Icon variant='facebook' mdxType="Icon" />
  <Icon variant='hamburger' mdxType="Icon" />
  <Icon variant='instagram' mdxType="Icon" />
  <Icon variant='linkedin' mdxType="Icon" />
  <Icon variant='meetings' mdxType="Icon" />
  <Icon variant='twitter' mdxType="Icon" />
  </ThemeProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      